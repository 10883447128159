<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        v-text="'Filters used...'"
        :block="full"
        :class="`${full ? 'mb-2' : 'ma-2'} btn-fix`"
      />
    </template>
    <v-card>
      <v-card-title v-text="'Filters used:'" />
      <v-card-text>
        <v-list>
          <v-list-item v-for="(f, i) in filters" :key="i">
            <v-list-item-icon>
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="f.name" />
              <v-list-item-subtitle v-text="f.value" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false" v-text="'OK'" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
export default {
  name: 'filter-popup',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    filters() {
      const filter = Object.keys(this.value || {})
        .map(key => {
          if (key === 'quadrant') {
            const {
              NE: { latitude: NElat, longitude: NElon },
              SW: { latitude: SWlat, longitude: SWlon }
            } = this.value[key] || {};
            const nullValue =
              Object.values(NElat).some(v => !v) &&
              Object.values(NElon).some(v => !v) &&
              Object.values(SWlat).some(v => !v) &&
              Object.values(SWlon).some(v => !v);
            if (!nullValue) {
              const {
                degrees: NElatD,
                minutes: NElatM,
                hemisphere: NElatH
              } = NElat;
              const {
                degrees: NElngD,
                minutes: NElngM,
                hemisphere: NElngH
              } = NElon;
              const {
                degrees: SWlatD,
                minutes: SWlatM,
                hemisphere: SWlatH
              } = SWlat;
              const {
                degrees: SWlngD,
                minutes: SWlngM,
                hemisphere: SWlngH
              } = SWlon;
              return {
                name: String(key).toUpperCase(),
                value: `
                    ${NElatD}º${Number(NElatM).toFixed(
                  2
                )}'${NElatH} ${NElngD}º${Number(NElngM).toFixed(2)}'${NElngH} |
                    ${SWlatD}º${Number(SWlatM).toFixed(
                  2
                )}'${SWlatH} ${SWlngD}º${Number(SWlngM).toFixed(2)}'${SWlngH}
                `
              };
            } else return null;
          } else if (
            key === 'angler' ||
            key === 'captain' ||
            key === 'enteredBy'
          ) {
            const { firstName, lastName } = this.value[key] || {};
            return firstName && lastName
              ? {
                  name: String(key).toUpperCase(),
                  value: `${firstName} ${lastName}`
                }
              : null;
          } else if (key === 'catchDate' || key === 'enteredByDate') {
            const [start, end] = this.value[key] || [null, null];
            return start && end
              ? {
                  name: String(key).toUpperCase(),
                  value: `${moment(start).format('MM/DD/YYYY')} ~ ${moment(
                    end
                  ).format('MM/DD/YYYY')}`
                }
              : null;
          } else if (key === 'species' || key === 'gear' || key === 'bait') {
            const { name } = this.value[key] || {};
            return name
              ? {
                  name: String(key).toUpperCase(),
                  value: name
                }
              : null;
          } else if (key === 'boat') {
            return this.value[key]
              ? {
                  name: String(key).toUpperCase(),
                  value: this.value[key]
                }
              : null;
          } else if (key === 'sort') {
            return null;
          } else {
            return this.value[key]
              ? {
                  name: String(key).toUpperCase(),
                  value: this.value[key]
                }
              : null;
          }
        })
        .filter(i => i !== null);
      return filter;
    }
  }
};
</script>

<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>
