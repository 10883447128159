<template>
  <v-dialog
    v-model="preview"
    max-width="1300px"
    scrollable
    :fullscreen="full"
    persistent
  >
    <v-card>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-html="title" />
        <v-spacer></v-spacer>
        <v-btn
          @click="getScreenshot"
          :loading="loading"
          rounded
          color="white"
          class="primary--text"
        >
          Get Screenshot
        </v-btn>
      </v-toolbar>
      <v-card-text ref="eventContent">
        <v-row>
          <v-col cols="12" md="6">
            <v-list dense>
              <v-list-item v-if="!isRelease">
                <v-list-item-content v-if="isRecapture">
                  <v-list-item-title v-html="'PRIOR TAG NUMBER'" />
                  <v-list-item-subtitle
                    v-html="
                      deepFind(data, 'priorTag.priorTagNumber') || defaultValue
                    "
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="`${isRecapture ? 'NEW ' : ''}TAG NUMBER`"
                  />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'number') || defaultValue"
                  />
                </v-list-item-content>
                <v-list-item-content v-if="!isRecapture">
                  <v-list-item-title v-html="`${title} DATE`" />
                  <v-list-item-subtitle
                    v-html="format(deepFind(data, 'createdDate'))"
                  />
                </v-list-item-content>
                <v-list-item-content v-if="!isRecapture">
                  <v-list-item-title v-html="`DATE ENTERED INTO SYSTEM`" />
                  <v-list-item-subtitle
                    v-html="format(deepFind(data, 'enteredDate'))"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="isRecapture">
                <v-list-item-content>
                  <v-list-item-title v-html="`${title} DATE`" />
                  <v-list-item-subtitle
                    v-html="format(deepFind(data, 'createdDate'))"
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="`DATE ENTERED INTO SYSTEM`" />
                  <v-list-item-subtitle
                    v-html="format(deepFind(data, 'enteredDate'))"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-html="'OCEAN'" />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'capture.ocean') || defaultValue"
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'SPECIES'" />
                  <span v-if="isRelease">
                    <v-list-item-subtitle
                      v-for="fish in deepFind(data, 'capture.fish')"
                      :key="fish.name"
                      v-html="`(${fish.qty}) ${fish.name}`"
                    />
                  </span>
                  <v-list-item-subtitle
                    v-html="
                      deepFind(data, 'capture.fish.specie.name') || defaultValue
                    "
                    v-else
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'SHARK ENCOUNTER'" />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'sharkEncounter') ? 'YES' : 'NO'"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-html="'ANGLER'" />
                  <v-list-item-subtitle
                    v-html="getUserName(deepFind(data, 'angler'))"
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'CAPTAIN'" />
                  <v-list-item-subtitle
                    v-html="getUserName(deepFind(data, 'captain'))"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-html="'FIRST MATE'" />
                  <v-list-item-subtitle
                    v-html="getUserName(deepFind(data, 'firstMate'))"
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'SECOND MATE'" />
                  <v-list-item-subtitle
                    v-html="getUserName(deepFind(data, 'secondMate'))"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title v-html="'LOCATION'" />
                  <v-list-item-subtitle
                    v-html="
                      deepFind(data, 'locality') ||
                      deepFind(data, 'coordinates.description') ||
                      defaultValue
                    "
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'LATITUDE'" />
                  <v-list-item-subtitle
                    v-html="
                      getCoordinates(deepFind(data, 'coordinates.latitude')) ||
                      defaultValue
                    "
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'LONGITUDE'" />
                  <v-list-item-subtitle
                    v-html="
                      getCoordinates(deepFind(data, 'coordinates.longitude')) ||
                      defaultValue
                    "
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!isRelease">
                <v-list-item-content>
                  <v-list-item-title v-html="'FISH LENGTH'" />
                  <v-list-item-subtitle
                    v-html="
                      getFishMetrics(deepFind(data, 'capture.fish.length')) ||
                      defaultValue
                    "
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'FISH WEIGHT'" />
                  <v-list-item-subtitle
                    v-html="
                      getFishMetrics(deepFind(data, 'capture.fish.weight')) ||
                      defaultValue
                    "
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'FIGHT TIME'" />
                  <v-list-item-subtitle
                    v-html="getFightTime(deepFind(data, 'capture.fish.fight'))"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!isRelease">
                <v-list-item-content>
                  <v-list-item-title v-html="'FISH CONDITION'" />
                  <v-list-item-subtitle
                    v-html="
                      deepFind(data, 'capture.fish.condition.name') ||
                      defaultValue
                    "
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'GEAR TYPE'" />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'capture.gear.name') || defaultValue"
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'TOURNAMENT'" />
                  <v-list-item-subtitle
                    v-html="
                      deepFind(data, 'capture.tournament.name') || defaultValue
                    "
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-html="'BAIT TYPE'" />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'capture.bait.name') || defaultValue"
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'HOOK TYPE'" />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'capture.hook.name') || defaultValue"
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'BOAT'" />
                  <v-list-item-subtitle
                    v-html="
                      deepFind(data, 'boatName') ||
                      deepFind(data, 'boat.name') ||
                      defaultValue
                    "
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="isRelease">
                <v-list-item-content>
                  <v-list-item-title v-html="'LINES IN'" />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'relLines') || defaultValue"
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'LINES OUT'" />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'relLines0') || defaultValue"
                  />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-html="'TOTAL LINES'" />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'relNumLi') || defaultValue"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" md="6">
            <fishing-locations
              ref="location"
              :fabPosition="false"
              :fabNavigation="false"
              :input="data"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-list dense>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title v-html="'NOTES'" />
                  <v-list-item-subtitle
                    v-html="deepFind(data, 'capture.notes') || defaultValue"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FishingLocations from '../map/FishingLocations.vue';
import * as html2canvas from 'html2canvas';
import {
  getImg,
  getEventType,
  NOT_SPECIFIED,
  formatDate,
  fixTimezone
} from '@/helpers/handler';
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel
} from '../users/viewmodels';
export default {
  components: { FishingLocations },
  name: 'event-preview',
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    preview: false,
    loading: false,
    title: 'TAG',
    data: window.structuredClone(initData),
    defaultValue: NOT_SPECIFIED
  }),
  mounted() {},
  watch: {
    value: {
      handler: 'updateData'
    }
  },
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isRelease() {
      return this.title === 'RELEASE';
    },
    isRecapture() {
      return this.title === 'RECAPTURE';
    }
  },
  methods: {
    updateData(data) {
      if (!data) {
        this.preview = false;
        return;
      }

      this.title = getEventType(data?.isTagOrRelease) || 'TAG';
      this.preview = true;
      setTimeout(() => {
        this.data = window.structuredClone({ ...initData, ...data });
      }, 100);
    },
    async getScreenshot() {
      this.loading = true;
      try {
        if (this.$refs.eventContent) {
          const output = await html2canvas(this.$refs.eventContent, {
            useCORS: true,
            onrendered: function (canvas) {
              document.body.appendChild(canvas);
            }
          });
          if (output) {
            const url = output.toDataURL();
            const number = this.data?.number;
            const prior = this.data?.priorTag?.priorTagNumber;
            const title = this.isRecapture
              ? `PriorTagNumber_${prior}`
              : `Number_${number}`;
            getImg(url, this.isRelease ? this.title : `${this.title}_${title}`);
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    getUserName(name) {
      const { firstName, lastName } = name || {};
      return firstName && lastName
        ? `${firstName} ${lastName}`
        : firstName || lastName || NOT_SPECIFIED;
    },
    format(value) {
      const fixedDate = fixTimezone(value);
      return formatDate(fixedDate);
    },
    getFightTime(time) {
      const { hours, minutes } = time || {};
      return hours ? `${hours}:${minutes || '00'}` : minutes || NOT_SPECIFIED;
    },
    getCoordinates(coordinates) {
      const { degrees, minutes, hemisphere } = coordinates || {};
      return degrees || minutes || hemisphere
        ? `${Number(degrees).toFixed(0)}º ${Number(minutes).toFixed(
            0
          )}' ${hemisphere}`
        : null;
    },
    getFishMetrics(metric) {
      const { value, type } = metric || {};
      return type ? `${value || 0} ${type}` : value || NOT_SPECIFIED;
    },
    deepFind(obj, path) {
      try {
        const parts = path.split('.');
        if (parts.length === 1) {
          return obj[parts[0]];
        }
        return this.deepFind(obj[parts[0]], parts.slice(1).join('.'));
      } catch {
        return null;
      }
    }
  }
};

const initData = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
  ...{ boatName: null }
};
</script>
