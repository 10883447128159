<template>
  <v-dialog
    v-model="onRecord"
    max-width="1200px"
    scrollable
    :fullscreen="!full"
    persistent
  >
    <v-card title>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-btn
          icon
          dark
          @click="
            onRecord = false;
            resetInfo();
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Juvenile Billfish </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-dialog
            v-model="onCreateBoat"
            max-width="400px"
            :fullscreen="!full"
          >
            <new-boat ref="newBoat" @close="onCreateBoat = false" />
          </v-dialog>
          <image-uploader
            :show="showImgUploader"
            @close="showImgUploader = false"
            v-model="fishPicture"
            :newRecord="newRecord"
          />
          <v-snackbar v-model="notify" :timeout="3000" top>
            {{ message }}
          </v-snackbar>
          <form ref="coordsForm" :disabled="loading" v-if="coordsStep">
            <coordinates-form
              v-model="input.coordinates"
              ref="coordsInputs"
              @change="setMarker"
            />
            <fishing-locations
              ref="fishingLocations"
              @coords="setCoords"
              @validate="notifyInvalidCoords"
              draggable
              :input="input"
            />
          </form>
          <v-form
            ref="juvenileForm"
            @submit="submitRecord"
            :disabled="loading"
            class="mt-5"
            v-else
          >
            <v-row>
              <v-col cols="12" sm="6" md="9">
                <auto-complete-location
                  ref="locations"
                  label="LOCATION NAME"
                  v-model="input.coordinates"
                  @ocean="(value) => (input.capture.ocean = value)"
                  @locate="setMarker"
                  addNew
                  :rules="rule"
                  :coordinates="input.coordinates"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <date-picker
                  v-model="input.createdDate"
                  label="DATE"
                  @clear="input.createdDate = null"
                  :rules="rule"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <auto-complete
                  label="OCEAN"
                  :rules="rule"
                  itemType="oceans"
                  v-model="input.capture.ocean"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <auto-complete
                  label="GEAR"
                  itemType="gears"
                  v-model="input.capture.gear"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <auto-complete
                  label="SPECIES"
                  :rules="rule"
                  itemType="species"
                  v-model="input.capture.fish.specie"
                  :ocean="input.capture.ocean"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3" v-if="specieSelected">
                <v-btn
                  dark
                  color="primary"
                  @click="showImgUploader = true"
                  block
                  :x-large="!full"
                >
                  <v-icon left> mdi-file-image-plus </v-icon>
                  LOAD IMAGE
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-medium">FIGHT TIME</h3>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="input.capture.fish.fight.hours"
                  label="HOURS"
                  v-mask="'###'"
                  min="0"
                  max="59"
                  maxLength="7"
                  outlined
                  :dense="full"
                  clearable
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="input.capture.fish.fight.minutes"
                  label="MINUTES"
                  v-mask="'##'"
                  min="0"
                  max="59"
                  maxLength="7"
                  outlined
                  :dense="full"
                  clearable
                  type="number"
                  :rules="timeRule"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  rows="1"
                  label="NOTES"
                  v-model="input.capture.notes"
                  outlined
                  clearable
                  dense
                  counter
                  :rules="notesRule"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col :class="full ? 'text-end' : 'text-center'">
                <v-bottom-sheet
                  v-model="sheet"
                  persistent
                  :fullscreen="!full"
                  :scrollable="!full"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="reset"
                      :x-large="!full"
                    >
                      ADD USER
                    </v-btn>
                  </template>
                  <new-user ref="newUser" @close="sheet = false" />
                </v-bottom-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <auto-complete-users
                  label="ANGLER"
                  v-model="input.angler"
                  @edit="editUser(input.angler)"
                  addNew
                  @add="sheet = true"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <auto-complete-users
                  label="CAPTAIN"
                  v-model="input.captain"
                  @edit="editUser(input.captain)"
                  addNew
                  @add="sheet = true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="0" sm="3" />
              <v-col cols="12" sm="6">
                <auto-complete
                  label="BOAT"
                  itemType="boats"
                  v-model="input.boat"
                  @new="createBoat"
                  add
                />
              </v-col>
              <v-col cols="0" sm="3" />
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <auto-complete-users
                  label="FIRST MATE"
                  v-model="input.firstMate"
                  @edit="editUser(input.firstMate)"
                  addNew
                  @add="sheet = true"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <auto-complete-users
                  label="SECOND MATE"
                  v-model="input.secondMate"
                  @edit="editUser(input.secondMate)"
                  addNew
                  @add="sheet = true"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-toolbar flat dark color="primary">
        <v-btn
          @click="coordsStep = true"
          color="white"
          class="primary--text"
          style="min-width: 164px"
          v-if="!coordsStep"
        >
          BACK
        </v-btn>
        <v-spacer />
        <v-btn
          @click="nextStep"
          color="white"
          class="primary--text"
          style="min-width: 164px"
          v-if="coordsStep"
        >
          NEXT
        </v-btn>
        <confirmation
          ref="confirmation"
          :data="input"
          event="juvenile"
          :isNew="!input.isTagOrRelease"
          @check="checkForm"
          @save="submitRecord"
          v-else
        />
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import AutoComplete from '../../../../components/appComponents/AutoComplete.vue';
import AutoCompleteUsers from '../../../../components/appComponents/AutoCompleteUsers.vue';
import AutoCompleteLocation from '../../../../components/appComponents/AutoCompleteLocation.vue';
import DatePicker from '../../../../components/appComponents/DatePicker.vue';
import ImageUploader from '../../../../components/appComponents/ImageUploader.vue';
import NewBoat from './NewBoat.vue';
import NewUser from './NewUser.vue';
import CoordinatesForm from './CoordinatesForm.vue';
import FishingLocations from '../../../map/FishingLocations.vue';
import Confirmation from './Confirmation.vue';
import {
  getRequestedUserData,
  getUserInfo,
  setRequestedUserData
} from '../../../../helpers/user';
import { validCoordinates } from '../../../../helpers/map';
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel
} from '../../viewmodels';
import { notifyMessage } from '../../../../helpers/notifications';
export default {
  name: 'juvenile-billfish-form',
  components: {
    AutoCompleteUsers,
    AutoComplete,
    NewUser,
    NewBoat,
    FishingLocations,
    CoordinatesForm,
    DatePicker,
    AutoCompleteLocation,
    Confirmation,
    ImageUploader
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    setUser: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    input: window.structuredClone(initInput),
    onCreateBoat: false,
    sheet: false,
    loading: false,
    newRecord: true,
    invalidCoords: false,
    notify: false,
    message: false,
    coordsStep: true,
    showImgUploader: false,
    fishPicture: null
  }),
  computed: {
    onRecord: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit('update:open', value);
      }
    },
    specieSelected() {
      return !!this.input.capture.fish?.specie?.name;
    },
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    rule() {
      return [(v) => !!v || 'Required'];
    },
    ruleCoords() {
      return [(v) => !(v === null || v === undefined) || 'Required'];
    },
    timeRule() {
      return [(v) => (v <= 59 && v >= 0) || '0 - 59 values allowed'];
    },
    notesRule() {
      return [(v) => !v || v?.length <= 300 || 'Maximum 300 characters'];
    }
  },
  methods: {
    ...mapActions('users', ['getUser']),
    ...mapActions('entry', [
      'addJuvenile',
      'editJuvenile',
      'deleteJuvenile',
      'getFishPicture',
      'uploadFishPicture'
    ]),
    async initRecord(record) {
      this.$emit('update:open', true);
      this.$refs.juvenileForm && (this.$refs.juvenileForm.scrollTop = 0);
      this.loading = true;
      this.resetInfo();
      this.newRecord = !record;
      if (!record) {
        if (this.setUser) {
          const userInfo = getUserInfo();
          const { data } = (await this.getUser({ id: userInfo?.id })) || {};
          if (data && userInfo?.selectedRole) {
            this.input[userInfo.selectedRole] = getRequestedUserData(data);
          }
        }
      } else {
        this.input = { ...this.input, ...record };
        const { _id, id } = record;
        await this.getPicture(_id || id);
      }
      this.loading = false;
    },
    resetInfo(action) {
      const input = window.structuredClone(initInput);

      if (!action || action === 'new') {
        this.input = input;
        this.coordsStep = true;
      }

      this.$refs.juvenileForm && this.$refs.juvenileForm.resetValidation();
    },
    reset() {
      this.$refs.newUser && this.$refs.newUser.init();
    },
    validateCoordinates() {
      return this.$refs.coordsInputs && this.$refs.coordsInputs.validate();
    },
    notifyInvalidCoords(isWater) {
      if (isWater) {
        this.invalidCoords = false;
        return;
      }
      this.invalidCoords = true;
      this.message = 'Invalid coords, no seawater surface';
      this.notify = true;
    },
    createBoat() {
      this.$refs.newBoat && this.$refs.newBoat.init();
      this.onCreateBoat = true;
    },
    editUser(user) {
      if (user?.id) {
        this.sheet = true;
        setTimeout(() => {
          if (this.$refs.newUser) {
            this.$refs.newUser.initUser(user.id);
          }
        }, 50);
      }
    },
    setCoords(coordinates) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }

      if (this.$refs.fishingLocations) {
        this.$refs.fishingLocations.getDescription(coordinates);
      }

      this.input.coordinates = { ...coordinates };
    },
    setBoatName(name) {
      if (name) {
        const { boatName, boat } = this.input || {};

        if (boatName && !boat?.name) {
          this.input.boat.name = boatName;
        }

        if (boat?.name) {
          this.input.boatName = boat.name;
        }
      }
    },
    setMarker(coordinates = null) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }
      this.input.coordinates = { ...this.input.coordinates, ...coordinates };
    },
    async getPicture(id) {
      if (!id) return;
      const response = await this.getFishPicture({ id });
      this.fishPicture =
        response?.data?.docs && JSON.parse(response.data.docs)?.image;
    },
    initMap(value) {
      setTimeout(async () => {
        if (value) {
          if (this.$refs.coordsInputs) {
            this.$refs.coordsInputs.updateForm(this.input.coordinates);
          }
          if (this.$refs.fishingLocations) {
            this.$refs.fishingLocations.handleData(this.input.coordinates);
          }
        } else {
          if (this.$refs.locations) {
            await this.$refs.locations.getLocationByName();
          }
        }
      }, 50);
    },
    nextStep() {
      if (this.coordsStep) {
        if (this.validateCoordinates()) {
          this.coordsStep = false;
        }
      } else {
        this.coordsStep = true;
      }
    },
    async checkForm() {
      const validCoords = validCoordinates(this.input.coordinates);
      if (!validCoords) {
        notifyMessage('Coordinates are not valid');
        return;
      }

      if (!this.$refs.juvenileForm) {
        return;
      }

      const validForm = this.$refs.juvenileForm.validate();
      if (!validForm) {
        notifyMessage('Some fields in the form need to be fulfilled');
        return;
      }

      if (this.$refs.confirmation) {
        this.$refs.confirmation.open = true;
      }
    },
    async submitRecord(action) {
      const data = setRequestedUserData(this.input);
      const response = await this.addEditRecord(data);

      const { success, response: record } = response?.data || {};
      if (success) {
        this.message = 'Record added successfully';
        this.notify = true;

        if (this.fishPicture && record?.id) {
          await this.uploadPicture(record?.id);
        }

        this.resetInfo(action);
        this.$emit('done');
        if (!action) {
          this.onRecord = false;
        }

        if (this.$refs.confirmation) {
          this.$refs.confirmation.open = false;
        }
      }
    },
    async addEditRecord(data) {
      if (this.newRecord) {
        return await this.addJuvenile(data);
      }

      return await this.editJuvenile(data);
    },
    async uploadPicture(id) {
      if (!id) return;
      await this.uploadFishPicture({
        id,
        image: this.fishPicture
      });
      this.fishPicture = null;
    }
  },
  watch: {
    'input.boatName': {
      handler: 'setBoatName'
    },
    'input.boat.name': {
      handler: 'setBoatName'
    },
    coordsStep: {
      handler: 'initMap',
      immediate: true
    },
    open: {
      handler: 'initMap'
    }
  }
};

const { enteredDate, createdDate, capture } = new CaptureViewModel();
const { fish, gear, ocean, notes } = capture;
const initInput = {
  ...new CrewViewModel(),
  capture: { fish, gear, ocean, notes },
  ...new CoordinatesViewModel(),
  ...{ boatName: null },
  enteredDate,
  createdDate
};
</script>
