<template>
  <v-container fluid class="down-top-padding">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <record-fish ref="recordFish" @done="loadListView" />
    <juvenile-billfish-form
      ref="juvenileForm"
      :open="openJuvenile"
      @update:open="(value) => (openJuvenile = value)"
      set-user
      @done="loadListView"
    />
    <preview :value="selected" @close="selected = null" />
    <v-dialog v-model="openMap" max-width="960px" scrollable :fullscreen="full">
      <template>
        <v-card title>
          <v-toolbar color="primary" flat dark max-height="100px">
            <v-btn text dark @click="closeMap"> Cancel </v-btn>
            <v-spacer />
            <v-toolbar-title>Select Quadrant</v-toolbar-title>
            <v-spacer />
            <v-btn text dark @click="openMap = false"> Apply </v-btn>
          </v-toolbar>
          <v-card-text class="mt-5">
            <fishing-locations
              ref="fishingLocations"
              @coords="setQuadrant"
              @polygon="setPolygon"
              :fabPosition="false"
              :fabNavigation="false"
              fab-base-map
            />
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-card flat>
      <v-card-title>
        <v-row>
          <v-col :cols="full && (searched ? '12' : '6')">
            <h4 class="font-weight-regular ml-5">
              <span v-if="searched">
                <span
                  v-if="!loading"
                  v-text="
                    `Found ${totalDocs || 0} ${
                      all ? 'records' : String(filter.event).toLowerCase()
                    }`
                  "
                />
                <span v-else>Loading ...</span>
              </span>
              <b v-else v-text="'Search Page'" />
            </h4>
          </v-col>
          <v-spacer v-if="!searched" />
          <v-col :cols="full && '6'" class="text-right" v-if="!searched">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-checkbox
                    v-model="draft"
                    label="Draft"
                    class="check-draft"
                  />
                </span>
              </template>
              <span>Filter by records saved in draft</span>
            </v-tooltip>
          </v-col>
          <v-col :class="!full && 'd-flex'">
            <v-btn
              :class="showReset || searched ? 'd-none' : full ? 'mb-2' : 'ma-2'"
              color="error"
              dark
              @click="resetFilter"
              :block="full"
            >
              <v-icon left dark>mdi-filter-remove</v-icon>
              Reset
            </v-btn>
            <v-spacer />
            <pop-up-filter v-model="filter" v-if="searched" />
            <v-btn
              :class="!full && 'ma-2'"
              color="primary"
              dark
              @click="submitFilter"
              :block="full"
            >
              <v-icon left dark>
                {{ searched ? 'mdi-chevron-left' : 'mdi-magnify' }}
              </v-icon>
              {{ searched ? 'Back to Search' : 'Search' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="!searched" class="px-5 mt-5">
        <v-form ref="filterForm" @submit="submitFilter">
          <v-btn class="d-none" type="submit"></v-btn>
          <v-row>
            <v-col cols="12" sm="3">
              <v-autocomplete
                label="SOURCE"
                :items="sources"
                :rules="rule"
                v-model="filter.source"
                :open-on-clear="false"
                :disabled="sources.length < 2"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                label="SEARCH FOR"
                :items="events"
                :rules="rule"
                v-model="filter.event"
                :open-on-clear="false"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="3">
              <auto-complete
                label="SPECIES"
                itemType="species"
                v-model="filter.species"
                :ocean="filter.ocean"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <auto-complete
                label="OCEAN"
                itemType="oceans"
                v-model="filter.ocean"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="2" md="1">
              <v-text-field
                label="PREFIX"
                v-model="prefix"
                @keyup="() => (prefix = String(prefix).toUpperCase())"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="5" md="2">
              <v-text-field
                label="TAG NUMBER"
                v-model="filter.tag"
                min="0"
                type="number"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="5" md="2">
              <v-text-field
                label="PRIOR TAG NUMBER"
                v-model="filter.priorTag"
                min="0"
                type="number"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <auto-complete-users
                label="USER"
                v-model="filter.user"
                @change="handleUserChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <!-- <auto-complete-users label="CAPTAIN" v-model="filter.captain" /> -->
              <v-autocomplete
                :items="['angler', 'captain', 'firstMate', 'secondMate']"
                label="ROLE"
                v-model="filter.role"
                :disabled="!filter.user"
                outlined
                dense
                clearable
              >
                <template v-slot:item="{ item }">
                  {{ item.toUpperCase() }}
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.toUpperCase() }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <auto-complete
                label="BOAT"
                itemType="boats"
                v-model="filter.boat"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                :items="['YES', 'NO']"
                label="SHARK ENCOUNTER"
                v-model="filter.sharkEncounter"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <date-picker
                v-model="filter.catchDate[0]"
                label="CATCH START DATE"
                @clear="filter.catchDate = [null, null]"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <date-picker
                v-model="filter.catchDate[1]"
                label="CATCH END DATE"
                :minDate="filter.catchDate[0]"
                :disable="!filter.catchDate[0]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <auto-complete-location
                ref="locations"
                label="LOCATION"
                v-model="filter.location"
                @ocean="(value) => (filter.ocean = value)"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <auto-complete
                label="HOOK"
                itemType="hooks"
                v-model="filter.hook"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <auto-complete-users
                label="ENTERED BY"
                v-model="filter.enteredBy"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <date-picker
                v-model="filter.enteredByDate[0]"
                label="ENTERED BY START DATE"
                @clear="filter.enteredByDate = [null, null]"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <date-picker
                v-model="filter.enteredByDate[1]"
                label="ENTERED BY END DATE"
                :minDate="filter.enteredByDate[0]"
                :disable="!filter.enteredByDate[0]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <h3>Region's top left corner</h3>
            </v-col>
            <v-col cols="12" sm="4" v-if="!full">
              <h3>Region's bottom right corner</h3>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-col cols="12" sm="2">
              <v-text-field
                label="LAT"
                v-model="latNE"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="LNG"
                v-model="lngNE"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" v-if="full">
              <h3>Region's bottom right corner</h3>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="LAT"
                v-model="latSW"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="LNG"
                v-model="lngSW"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn block depressed color="primary" @click="launchMap">
                Use Map
                <v-icon right dark>mdi-map-search</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <list-view
        ref="listView"
        :title="'Search Results'"
        :headers="dynamicHeaders"
        :records="isJuvenile ? processedItems : records"
        :async-loading="loading"
        :footer-options="footer"
        :total-docs="totalDocs"
        :hideHeader="true"
        @onPaginate="(v) => (options = v)"
        v-if="searched"
        exportable
        @export="exportFile"
        scrollable
      >
        <template slot="list-item" slot-scope="{ row }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="primary"
                fab
                dark
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click="openEntryDialog(row)"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="red"
                fab
                dark
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click.stop="deleteAction(row)"
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                fab
                class="mr-1 primary--text"
                v-bind="attrs"
                v-on="on"
                @click.stop="selected = { ...row }"
              >
                <v-icon> mdi-information-variant </v-icon>
              </v-btn>
            </template>
            <span>Preview</span>
          </v-tooltip>
        </template>
        <template slot="captures" slot-scope="{ row }">
          <span v-if="isFishArray(row)">
            <v-list-item v-for="(item, i) in row.capture.fish" :key="i">
              <v-list-item-content>
                {{ item.name || 'UNASSIGNED NAME' }}
              </v-list-item-content>
              <v-list-item-icon v-if="item.qty">
                <template> {{ item.qty }} </template>
              </v-list-item-icon>
            </v-list-item>
          </span>
          <span v-else>
            <v-list-item>
              <v-list-item-content>
                {{ getSpecieName(row) }}
              </v-list-item-content>
            </v-list-item>
          </span>
        </template>
        <template slot="header" slot-scope="{ row }" v-if="totalDocs">
          <th
            class="text-left pl-5 pb-5 header_size"
            v-for="(head, i) in row.headers"
            :key="i"
          >
            <div class="d-flex head_div">
              <span
                class="caption font-weight-bold grey--text text--darken-2 pr-1"
              >
                {{ head.text }}
              </span>
              <v-btn icon v-if="!!head['sort']" @click="sort(head)" x-small>
                <v-icon v-if="head['order']">mdi-arrow-down</v-icon>
                <v-icon v-else>mdi-arrow-up</v-icon>
              </v-btn>
            </div>
          </th>
        </template>
      </list-view>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ListView from '@/components/appComponents/ListView.vue';
import { coordsPatterns, notifyMessage, getFile } from '@/helpers/handler';
import FishingLocations from '@/views/map/FishingLocations.vue';
import RecordFish from '../users/forms/RecordFish.vue';
import _ from 'lodash';
import AutoComplete from '../../components/appComponents/AutoComplete.vue';
import AutoCompleteUsers from '../../components/appComponents/AutoCompleteUsers.vue';
import PopUpFilter from './PopUpFilter.vue';
import DatePicker from '../../components/appComponents/DatePicker.vue';
import { notifyConfirmation } from '../../helpers/notifications';
import Preview from './Preview.vue';
import JuvenileBillfishForm from '../users/forms/sections/JuvenileBillfishForm.vue';
import AutoCompleteLocation from '../../components/appComponents/AutoCompleteLocation.vue';
import { RECORD_TYPES, SOURCE_TYPES } from '../../helpers/constants';
export default {
  name: 'search',
  components: {
    ListView,
    FishingLocations,
    RecordFish,
    AutoComplete,
    AutoCompleteUsers,
    PopUpFilter,
    DatePicker,
    Preview,
    JuvenileBillfishForm,
    AutoCompleteLocation
  },
  data: () => ({
    searched: false,
    records: [],
    quadrants: [],
    filter: _.cloneDeep(initFilter),
    loading: false,
    footer: {
      pageCount: 0
    },
    totalDocs: 0,
    totals: {
      tags: 0,
      releases: 0,
      recaptures: 0
    },
    header: Header,
    openMap: false,
    selected: undefined,
    itemFilter: null,
    rule: [(v) => !!v || 'Required'],
    options: null,
    order: false,
    overlay: false,
    preview: false,
    draft: false,
    prefix: 'BF',
    openJuvenile: false,
    processedItems: []
  }),
  computed: {
    dynamicHeaders() {
      if (this.filter.event === 'JUVENILES') {
        return [
          ...this.header,
          {
            text: 'Picture',
            align: 'start',
            sortable: false,
            value: 'picture'
          }
        ];
      }
      return this.header;
    },
    events() {
      return [...RECORD_TYPES.map((v) => v.toUpperCase()), 'ALL'];
    },
    sources() {
      if (SOURCE_TYPES.length < 2) {
        return SOURCE_TYPES;
      }

      return [...SOURCE_TYPES.map((v) => v.toUpperCase()), 'ALL'];
    },
    all() {
      return this.filter.event === 'ALL';
    },
    isJuvenile() {
      return this.filter.event === 'JUVENILES';
    },
    latNE: {
      get: function () {
        if (this.filter.quadrant) {
          const { degrees, minutes, hemisphere } =
            this.filter.quadrant?.NE?.latitude;
          return !!degrees || !!minutes || !!hemisphere
            ? `${degrees} ${Number(minutes).toFixed(2)} ${hemisphere}`
            : null;
        } else return null;
      },
      set: function (value) {
        if (coordsPatterns.lat.isDDM.test(value)) {
          const [degrees, minutes, hemisphere] = value.split(' ');
          this.filter.quadrant.NE.latitude = {
            degrees,
            minutes,
            hemisphere
          };
        }
      }
    },
    lngNE: {
      get: function () {
        if (this.filter.quadrant) {
          const { degrees, minutes, hemisphere } =
            this.filter.quadrant?.NE?.longitude;
          return !!degrees || !!minutes || !!hemisphere
            ? `${degrees} ${Number(minutes).toFixed(2)} ${hemisphere}`
            : null;
        } else return null;
      },
      set: function (value) {
        if (coordsPatterns.lng.isDDM.test(value)) {
          const [degrees, minutes, hemisphere] = value.split(' ');
          this.filter.quadrant.NE.longitude = {
            degrees,
            minutes,
            hemisphere
          };
        }
      }
    },
    latSW: {
      get: function () {
        if (this.filter.quadrant) {
          const { degrees, minutes, hemisphere } =
            this.filter.quadrant?.SW?.latitude;
          return !!degrees || !!minutes || !!hemisphere
            ? `${degrees} ${Number(minutes).toFixed(2)} ${hemisphere}`
            : null;
        } else return null;
      },
      set: function (value) {
        if (coordsPatterns.lat.isDDM.test(value)) {
          const [degrees, minutes, hemisphere] = value.split(' ');
          this.filter.quadrant.SW.latitude = {
            degrees,
            minutes,
            hemisphere
          };
        }
      }
    },
    lngSW: {
      get: function () {
        if (this.filter.quadrant) {
          const { degrees, minutes, hemisphere } =
            this.filter.quadrant?.SW?.longitude;
          return !!degrees || !!minutes || !!hemisphere
            ? `${degrees} ${Number(minutes).toFixed(2)} ${hemisphere}`
            : null;
        } else return null;
      },
      set: function (value) {
        if (coordsPatterns.lng.isDDM.test(value)) {
          const [degrees, minutes, hemisphere] = value.split(' ');
          this.filter.quadrant.SW.longitude = {
            degrees,
            minutes,
            hemisphere
          };
        }
      }
    },
    showReset() {
      return _.isEqual(this.filter, initFilter);
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  mounted() {
    this.resetFilter();
  },
  watch: {
    options: {
      handler: 'loadListView'
    },
    records: {
      handler: 'getPictures'
    }
  },
  methods: {
    ...mapActions('query', ['getFilterResults']),
    ...mapActions('entry', [
      'editTag',
      'editRecapture',
      'editRelease',
      'deleteTag',
      'deleteRelease',
      'deleteRecapture',
      'deleteJuvenile',
      'getFishPicture'
    ]),
    async getPictures(items = []) {
      this.processedItems = [];
      if (!items?.length || !this.isJuvenile) {
        return;
      }
      this.loading = true;
      this.processedItems = await Promise.all(
        items.map(async (item) => {
          const { id, _id } = item || {};
          const response = await this.getFishPicture({ id: id || _id });
          const { docs } = response?.data || {};
          const image = docs && JSON.parse(docs)?.image;
          const { mime, data } = image || {};
          if (mime && data) {
            item.picture = `data:${mime};base64,${data}`;
          }
          return item;
        })
      );
      this.loading = false;
    },
    async openEntryDialog(item) {
      if (item?.isTagOrRelease === 'J') {
        if (this.$refs.juvenileForm) {
          await this.$refs.juvenileForm.initRecord(item);
        }
        return;
      }

      const data = { userId: 'notapplicable', record: item };
      const title = this.filter.event === 'ALL' ? '' : this.filter.event;
      this.$refs.recordFish && (await this.$refs.recordFish.init(data, title));
    },
    async sort(head) {
      this.header.forEach((item) => {
        if (item.sort === head?.sort) {
          item.order = !head?.order;
        } else {
          item.order = true;
        }
      });
      const header = this.header.find((item) => item?.sort === head?.sort);
      this.filter.sort = header.sort;
      this.order = header.order;
      await this.loadListView();
    },
    async loadListView() {
      const { page, itemsPerPage: limit } = this.options || {};
      if (!page || !limit) {
        return;
      }

      this.loading = true;
      this.totals = {
        tags: 0,
        releases: 0,
        recaptures: 0
      };
      this.totalDocs = 0;
      this.records = [];

      const response = await this.getFilterResults({
        ...this.filter,
        ...(this.filter.tag ? { tag: this.prefix + this.filter.tag } : {}),
        ...(this.filter.priorTag
          ? { priorTag: this.prefix + this.filter.priorTag }
          : {}),
        page,
        limit,
        order: this.order ? 1 : 2,
        draft: this.draft
      });

      if (response?.data) {
        const { docs, totalPages, page, totalDocs } = response.data || {};
        if (!totalDocs || !docs) {
          this.loading = false;
          return;
        }
        this.records = this.modifyArray(docs);
        this.footer.pageCount = totalPages;
        this.footer.page = page;
        this.totalDocs = totalDocs || 0;
        this.totals.tags = response.data?.totalTags || 0;
        this.totals.releases = response.data?.totalReleases || 0;
        this.totals.recaptures = response.data?.totalRecaptures || 0;
      }
      this.loading = false;
    },
    modifyArray(array) {
      return array?.map((item) => {
        item.captain.fullName =
          item?.captain?.firstName && item?.captain?.lastName
            ? `${item?.captain?.firstName} ${item?.captain?.lastName}`
            : '';
        item.angler.fullName =
          item?.angler?.firstName && item?.angler?.lastName
            ? `${item?.angler?.firstName} ${item?.angler?.lastName}`
            : '';
        return item;
      });
    },
    setQuadrant(data) {
      this.filter.quadrant = data;
    },
    setPolygon(data) {
      this.filter.polygon = data;
    },
    launchMap() {
      this.openMap = true;
      setTimeout(() => {
        if (this.$refs.fishingLocations) {
          this.$refs.fishingLocations.draw();
        }
      }, 50);
    },
    closeMap() {
      this.$refs.fishingLocations && this.$refs.fishingLocations.clear();
      this.openMap = false;
    },
    resetFilter() {
      this.filter = window.structuredClone(initFilter);
      if (this.$refs.fishingLocations) {
        this.$refs.fishingLocations.clearLayers();
      }
    },
    submitFilter() {
      if (!this.searched) {
        const valid = this.$refs.filterForm && this.$refs.filterForm.validate();
        valid && (this.searched = true);
      } else this.searched = false;
    },
    async exportFile(format) {
      this.$refs.listView && (this.$refs.listView.loadingExport = true);
      const { page, itemsPerPage: limit } = this.options || {};
      const { data } =
        (await this.getFilterResults({
          ...this.filter,
          ...(this.filter.tag ? { tag: this.prefix + this.filter.tag } : {}),
          ...(this.filter.priorTag
            ? { priorTag: this.prefix + this.filter.priorTag }
            : {}),
          page,
          limit,
          export: true,
          format
        })) || {};
      getFile(data?.docs, format, 'Search Results');
      this.$refs.listView && (this.$refs.listView.loadingExport = false);
    },
    getSpecieName(item) {
      return item?.capture?.fish?.specie?.name || '';
    },
    isFishArray(item) {
      return item?.capture?.fish && item.capture.fish instanceof Array;
    },
    deleteAction(row) {
      notifyConfirmation(
        'You will delete this event, this action will be irreversible',
        'Are you sure to take this action?',
        async () => {
          this.overlay = true;
          try {
            let response = null;
            const { _id: id, isTagOrRelease } = row || {};
            switch (isTagOrRelease) {
              case 'C':
                response = await this.deleteRecapture({ id });
                break;

              case 'R':
                response = await this.deleteRelease({ id });
                break;

              case 'T':
                response = await this.deleteTag({ id });
                break;

              case 'J':
                response = await this.deleteJuvenile({ id });
                break;

              default:
                break;
            }
            if (response) {
              notifyMessage('Event successfully removed');
              await this.loadListView();
              this.overlay = false;
              return;
            }
            notifyMessage("Something went wrong, delete action can't complete");
          } catch (error) {
            notifyMessage("Something went wrong, delete action can't complete");
          }
          this.overlay = false;
        }
      );
    },
    handleUserChange() {
      if (!this.user) {
        this.filter.role = undefined;
      }
    }
  }
};

const initFilter = {
  source: 'TBF',
  event: 'ALL',
  species: undefined,
  ocean: undefined,
  polygon: null,
  quadrant: {
    NE: {
      latitude: {
        degrees: null,
        minutes: null,
        hemisphere: null
      },
      longitude: {
        degrees: null,
        minutes: null,
        hemisphere: null
      }
    },
    SW: {
      latitude: {
        degrees: null,
        minutes: null,
        hemisphere: null
      },
      longitude: {
        degrees: null,
        minutes: null,
        hemisphere: null
      }
    }
  },
  tag: null,
  priorTag: null,
  user: undefined,
  role: undefined,
  enteredBy: undefined,
  boat: undefined,
  gear: undefined,
  hook: undefined,
  location: null,
  catchDate: [null, null],
  enteredByDate: [null, null],
  sort: null,
  sharkEncounter: undefined
};

const Header = [
  { text: 'Actions', value: 'actions', sortable: false },
  {
    text: 'Tag Number',
    align: 'start',
    sortable: false,
    value: 'number',
    sort: 'number',
    order: true
  },
  {
    text: 'Prior Tag Number',
    align: 'start',
    sortable: false,
    value: 'priorTag.priorTagNumber',
    sort: 'priorNumber',
    order: true
  },
  {
    text: 'Angler',
    align: 'start',
    sortable: false,
    value: 'angler.fullName',
    sort: 'angler',
    order: true
  },
  {
    text: 'Captain',
    align: 'start',
    sortable: false,
    value: 'captain.fullName',
    sort: 'captain',
    order: true
  },
  {
    text: 'Boat',
    align: 'start',
    sortable: false,
    value: 'boatName',
    sort: 'boat',
    order: true
  },
  {
    text: 'Ocean',
    align: 'start',
    sortable: false,
    value: 'capture.ocean',
    sort: 'ocean',
    order: true
  },
  {
    text: 'Species',
    align: 'start',
    sortable: false,
    value: 'captures',
    sort: 'species',
    order: true
  },
  {
    text: 'Location Description',
    align: 'start',
    sortable: false,
    value: 'locality',
    sort: 'location',
    order: true
  },
  {
    text: 'Tag/Release Date',
    align: 'start',
    sortable: false,
    value: 'createdDate',
    sort: 'tagDate',
    order: true
  },
  {
    text: 'Entered Date',
    align: 'start',
    sortable: false,
    value: 'enteredDate',
    sort: 'enteredDate',
    order: true
  },
  {
    text: 'Entered By',
    align: 'start',
    sortable: false,
    value: 'enteredBy',
    sort: 'enteredBy',
    order: true
  }
  // {
  //   text: 'Source',
  //   align: 'start',
  //   sortable: false,
  //   value: 'source'
  // }
];
</script>

<style scoped>
.v-list-item {
  padding: 0;
}

.header_size {
  min-width: 150px;
}

.check-draft {
  display: inline-block;
  padding-top: 0;
  margin-right: 1em;
}

.head_div {
  width: max-content;
}
</style>
